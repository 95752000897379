import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, distinctUntilChanged, of, switchMap, tap } from 'rxjs';
import { GetUserApiModel, PostAccountApiModel } from '../auth.types';
import { Params } from '@angular/router';
import { environment } from '../../../environments/environment';


export type OAuthBridgeState = {
  platform: string;
  query: Params;
}
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user = new BehaviorSubject<GetUserApiModel | null>(null);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient) { }

  get appUrl(): string {
    return this.document.location.origin
  }

  public get user$() {
    return this._user.asObservable().pipe(
      distinctUntilChanged(),
      switchMap((user) => user ? of(user) : this.userInfo())
    );
  }

  userInfo() {
    return this.http.get<GetUserApiModel>("/api/whoami", { headers: { 'x-skip-interceptor': 'true' } })
      .pipe(
        tap((user) => this._user.next(user)),
        catchError(() => of(null))
      )
  }


  signInWithGoogle(state: string = "") {
    const query = new HttpParams()
      .set("response_type", "code")
      .set("client_id", "360018051657-2vip3fqhke7bvkh3u67oomq84oof4api.apps.googleusercontent.com")
      .set("scope", ["https://www.googleapis.com/auth/userinfo.email", "https://www.googleapis.com/auth/userinfo.profile"].join(" "))
      .set("redirect_uri", `${this.appUrl}/auth/redirect/google`)
      .set("state", state);
    this.document.location.href = `https://accounts.google.com/o/oauth2/auth?${query}`
  }

  signOut() {
    this.document.location.href = `${this.appUrl}/auth/signout`;
  }

  oauthBridgeForPlatform(platform: string, code: string) {
    const query = new HttpParams()
      .set("code", code);

    this.document.location.href = `/auth/redirect/${platform}?${query}`
  }

  createAccount(account: PostAccountApiModel) {
    return this.http.post<void>("/api/account", account, { observe: 'response' })
      .pipe(switchMap(() => this.userInfo()))
  }

  oauthHubspot() {
    const scopes = [
      "oauth",
      "tickets",
      "crm.objects.companies.read",
      "crm.objects.deals.read",
      "crm.objects.contacts.read"
    ];
    const query = new HttpParams()
      .set("response_type", "code")
      .set("client_id", environment.hubspotClientId)
      .set("scope", scopes.join(" "))
      .set("redirect_uri", `${this.appUrl}/auth/oauth-bridge/hubspot`);

    this.document.location.href = `https://app-eu1.hubspot.com/oauth/authorize?${query}`
  }

  oauthIntercom() {
    const query = new HttpParams()
      .set("response_type", "code")
      .set("client_id", environment.intercomClientId)
      // .set("scope", ["tickets","crm.objects.contacts.read","oauth"].join(" "))
      .set("redirect_uri", `${this.appUrl}/auth/oauth-bridge/intercom`);

    this.document.location.href = `https://app.intercom.com/a/oauth/connect?${query}`
  }
}
